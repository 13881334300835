import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';
import { required, composeValidators } from '../../util/validators';

import css from './EditListingInfoForm.module.css';

const EditListingInfoFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const companyMessage = intl.formatMessage({
        id: 'EditListingInfoForm.company'
      });
      const companyPlaceholderMessage = intl.formatMessage({
        id: 'EditListingInfoForm.companyPlaceholder',
      });

      const websiteMessage = intl.formatMessage({
        id: 'EditListingInfoForm.website',
      });
      const websitePlaceholderMessage = intl.formatMessage({
        id: 'EditListingInfoForm.websitePlaceholder',
      });

      const addressMessage = intl.formatMessage({
        id: 'EditListingInfoForm.address',
      });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingInfoForm.addressPlaceholder',
      });

      const companyRequiredMessage = intl.formatMessage({
        id: 'EditListingInfoForm.companyRequired',
      });
      const websiteRequiredMessage = intl.formatMessage({
        id: 'EditListingInfoForm.websiteRequired',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingInfoForm.addressRequired',
      });


      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingInfoForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingInfoForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="company"
            name="company"
            className={css.title}
            type="text"
            label={companyMessage}
            placeholder={companyPlaceholderMessage}
            validate={composeValidators(required(companyRequiredMessage))}
          />

          <FieldTextInput
            id="website"
            name="website"
            className={css.title}
            type="text"
            label={websiteMessage}
            placeholder={websitePlaceholderMessage}
            validate={composeValidators(required(websiteRequiredMessage))}
          />

          <FieldTextInput
            id="address"
            name="address"
            className={css.title}
            type="text"
            label={addressMessage}
            placeholder={addressPlaceholderMessage}
            validate={composeValidators(required(addressRequiredMessage))}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingInfoFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingInfoFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingInfoFormComponent);
