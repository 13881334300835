/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'team_size',
    label: 'Team Size',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_teamSize'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      schemaType: 'enum',
      options: [
        { key: '1', label: '1-5' },
        { key: '2', label: '6-10' },
        { key: '3', label: '11-15' },
        { key: '4', label: '16-20' },
        { key: '5', label: '20+' },
        { key: 'flexible', label: 'Flexible' }
      ],
    },
  },
  {
    id: 'how_long',
    label: 'How long',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_howLong'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      schemaType: 'enum',
      options: [
        { key: '3months', label: '3-6 months' },
        { key: '6months', label: '6-12 months' },
        { key: '1year', label: '1-2 years' },
        { key: '2years', label: '2+ years' },
        { key: 'flexible', label: 'Flexible' }
      ],
    },
  },
  {
    id: 'workstyle',
    label: 'Workstyle',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_workstyle'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      schemaType: 'enum',
      options: [
        { key: '5days', label: '5 days / week' },
        { key: '4days', label: '4 days / week' },
        { key: '3days', label: '3 days / week' },
        { key: '2days', label: '2 days / week' },
        { key: '1day', label: '1 day / week' },
        { key: 'flexible', label: 'Flexible' }
      ],
    },
  },
  // {
  //   id: 'dates',
  //   label: 'Dates',
  //   type: 'BookingDateRangeFilter',
  //   group: 'primary',
  //   // Note: BookingDateRangeFilter is fixed filter,
  //   // you can't change "queryParamNames: ['dates'],"
  //   queryParamNames: ['dates'],
  //   config: {},
  // },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 25000,
      step: 5,
    },
  },
  {
    id: 'when',
    label: 'When',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_when'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      schemaType: 'enum',
      options: [
        { key: 'immediately', label: 'Immediately' },
        { key: 'next', label: 'Next months' },
        { key: 'flexible', label: 'Flexible' }
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      schemaType: 'enum',
      options: [
        { key: 'design', label: 'Design' },
        { key: 'art', label: 'Art' },
        { key: 'tech', label: 'Tech' },
        { key: 'r-d', label: 'R&D' },
        { key: 'legal', label: 'Legal' },
        { key: 'retail', label: 'Retail' },
        { key: 'finance', label: 'Finance' },
        { key: 'services', label: 'Services' },
        { key: 'other', label: 'Other' }
      ],
    },
  },
  {
    id: 'amenities',
    label: 'Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      schemaType: 'multi-enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'wifi',
          label: 'Wifi',
        },
        {
          key: 'screens',
          label: 'TV Screens',
        },
        {
          key: 'drawing_tables',
          label: 'Drawing Tables',
        },
        {
          key: 'desk_tables',
          label: 'Desk Tables',
        },
        {
          key: 'lounge',
          label: 'Lounge areas',
        },
        {
          key: 'phone',
          label: 'Phone booths',
        },
        {
          key: 'meeting_rooms_sm',
          label: 'Small Meeting rooms',
        },
        {
          key: 'meeting_rooms_md',
          label: 'Medium Meeting rooms',
        },
        {
          key: 'meeting_rooms_lg',
          label: 'Large Meeting rooms',
        },
        {
          key: 'supplies',
          label: 'Office supplies',
        },
        {
          key: 'printers',
          label: 'Printers',
        },
        {
          key: '3d_printers',
          label: '3D Printers',
        },
        {
          key: 'plotter',
          label: 'Large Format Plotter',
        },
        {
          key: 'wellness',
          label: 'Wellness room',
        },
        {
          key: 'mothers',
          label: 'Mothers room',
        },
        {
          key: 'event',
          label: 'Event space',
        },
        {
          key: 'library',
          label: 'Books library',
        },
        {
          key: 'lighting',
          label: 'Curated lighting',
        },
        {
          key: 'full_kitchen',
          label: 'Full kitchen',
        },
        {
          key: 'half_kitchen',
          label: 'Half kitchen',
        },
        {
          key: 'cooking',
          label: 'Cooking basics',
        },
        {
          key: 'refrigerators',
          label: 'Refrigerators',
        },
        {
          key: 'coffee ',
          label: 'Coffee maker',
        },
        {
          key: 'snacks',
          label: 'Snacks',
        },
        {
          key: 'barista',
          label: 'Barista',
        },
        {
          key: 'air_conditioner',
          label: 'Air conditioner',
        },
        {
          key: 'heater',
          label: 'Heater',
        },
        {
          key: 'smoke_alarm',
          label: 'Smoke alarm',
        },
        {
          key: 'receptionist',
          label: 'Receptionist',
        },
        {
          key: 'dog',
          label: 'Dog Friendly',
        },
        {
          key: 'quiet',
          label: 'Quiet environment',
        },
        {
          key: 'outdoor',
          label: 'Outdoor space',
        },
        {
          key: 'bike',
          label: 'Bike storage',
        },
        {
          key: 'parking',
          label: 'Parking',
        },
        {
          key: 'cleaning',
          label: 'Cleaning service daily',
        },
        {
          key: 'access',
          label: 'Access 24/7',
        },
        {
          key: 'security',
          label: 'Building security',
        },
      ],
    },
  },
  {
    id: 'manifesto',
    label: 'Manifesto',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_manifesto'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',
      schemaType: 'multi-enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [

        {
          key: 'respect',
          label: 'We respect everyone',
        },
        {
          key: 'friendly',
          label: 'We have a friendly environment',
        },
        {
          key: 'office',
          label: 'Our office is your office',
        },
        {
          key: 'dialogue',
          label: 'We welcome dialogue',
        },
        {
          key: 'problems',
          label: 'We solve problems together',
        },
        {
          key: 'welcome',
          label: 'We welcome all employees and clients',
        },
        {
          key: 'tidy',
          label: 'We keep things tidy',
        },
        {
          key: 'clean',
          label: 'We clean up after lunch / snacks',
        },
        {
          key: 'meeting_clean',
          label: 'We leave meeting rooms clean',
        },
        {
          key: 'fridge',
          label: 'We clean the fridge weekly',
        },
        {
          key: 'dishwasher',
          label: 'We empty the dishwasher',
        },
        {
          key: 'noise',
          label: 'We maintain a low noise environment when sharing',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
