import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingSearchingForm } from '../../forms';
import { ListingLink } from '../../components';
import config from '../../config';

import css from './EditListingFeaturesPanel.module.css';

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingSearchingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingSearchingPanel.createListingTitle" />
  );


  const workstyleOptions = findOptionsForSelectFilter('workstyle', config.custom.filters);
  const teamSizeOptions = findOptionsForSelectFilter('team_size', config.custom.filters);
  const howLongOptions = findOptionsForSelectFilter('how_long', config.custom.filters);
  const whenOptions = findOptionsForSelectFilter('when', config.custom.filters);

  const workstyle = publicData && publicData.workstyle;
  const teamSize = publicData && publicData.teamSize;
  const howLong = publicData && publicData.howLong;
  const when = publicData && publicData.when;
  const initialValues = { workstyle, teamSize, howLong, when };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingSearchingForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { workstyle, teamSize, howLong, when } = values;
          const updateValues = {
            publicData: { workstyle, teamSize, howLong, when }
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        workstyle={workstyleOptions}
        teamSize={teamSizeOptions}
        howLong={howLongOptions}
        when={whenOptions}
      />
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
