import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-san-francisco',
    predictionPlace: {
      address: 'San Francisco, California',
      bounds: new LatLngBounds(new LatLng(37.86648, -122.30107), new LatLng(37.67076, -122.54463)),
    },
  },
  {
    id: 'default-los-angeles',
    predictionPlace: {
      address: 'Los Angeles, California',
      bounds: new LatLngBounds(new LatLng(34.16143, -118.12672), new LatLng(33.89999, -118.52144)),
    },
  },
  {
    id: 'default-new-york',
    predictionPlace: {
      address: 'New York City, New York',
      bounds: new LatLngBounds(new LatLng(40.91757, -73.70027), new LatLng(40.47739, -74.25963)),
    },
  },
];
export default defaultLocations;