import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.legalMatters}>
        <ul className={css.tosAndPrivacy}>
          <li>
            <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
              <FormattedMessage id="Footer.privacyPolicy" />
            </NamedLink>
          </li>
          <li>
            <NamedLink name="TermsOfServicePage" className={css.legalLink}>
              <FormattedMessage id="Footer.termsOfUse" />
            </NamedLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
