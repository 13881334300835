import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import { FieldSelect } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './EnquiryForm.module.css';

const EnquiryFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
      } = fieldRenderProps;

      const messageLabel = intl.formatMessage(
        {
          id: 'EnquiryForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'EnquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const companyRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.companyRequired',
      });
      const companyRequired = validators.requiredAndNonEmptyString(companyRequiredMessage);

      const industryRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.industryRequired',
      });
      const industryRequired = validators.requiredAndNonEmptyString(industryRequiredMessage);

      const websiteRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.websiteRequired',
      });
      const websiteRequired = validators.validBusinessURL(websiteRequiredMessage);

      const companyPlaceholderMessage = intl.formatMessage({
        id: 'EnquiryForm.companyPlaceholder',
      });

      const websitePlaceholderMessage = intl.formatMessage({
        id: 'EnquiryForm.websitePlaceholder',
      });

      const industryPlaceholderMessage = intl.formatMessage({
        id: 'EnquiryForm.industryPlaceholder',
      });
      const bookPlaceholderMessage = intl.formatMessage({
        id: 'EnquiryForm.bookPlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      const categories = findOptionsForSelectFilter('category', config.custom.filters);

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          {/* <IconEnquiry className={css.icon} /> */}
          <h2 className={css.heading}>
            <FormattedMessage id="EnquiryForm.heading" values={{ listingTitle }} />
          </h2>
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="Message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            defaultValue={messagePlaceholder}
            //validate={messageRequired}
          />
          <h3 className={css.subHeading}>
            <FormattedMessage id="EnquiryForm.subheading"/>
          </h3>
          <FieldTextInput
            id="company"
            name="Company"
            className={css.field}
            type="text"
            //label={companyMessage}
            placeholder={companyPlaceholderMessage}
            validate={companyRequired}
          />
          <FieldTextInput
            id="website"
            name="Website"
            className={css.field}
            type="text"
            //label={websiteMessage}
            placeholder={websitePlaceholderMessage}
            validate={websiteRequired}
          />
          <FieldTextInput
            id="industry"
            name="Industry"
            className={css.field}
            type="text"
            //label={websiteMessage}
            placeholder={industryPlaceholderMessage}
            validate={industryRequired}
          />
          {/* <FieldSelect
            className={css.category}
            name="Industry"
            id="industry"
            label="Category"
          >
            <option disabled value="">
              Choose an option
            </option>
            {categories.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect> */}
          <h3 className={css.subHeading}>
            <FormattedMessage id="EnquiryForm.bookTour" />
          </h3>
          <FieldTextInput
            id="book"
            name="book"
            className={css.field}
            type="text"
            //label={websiteMessage}
            placeholder={bookPlaceholderMessage}
          />
          <div className={submitButtonWrapperClassName}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="EnquiryForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="EnquiryForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

EnquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

EnquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EnquiryForm = compose(injectIntl)(EnquiryFormComponent);

EnquiryForm.displayName = 'EnquiryForm';

export default EnquiryForm;
